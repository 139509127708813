import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Footer = () => {
  return (
    <section>
      <Container>
        <div className="line-2"></div>
        <Row>
          <Col sm={12}>
            <p className="text-center pt-30 footer-bottom">
              © 2025 NUM8ERS • All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
