import { React, useState, useEffect } from "react";
import "./keyconcept.css";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Key_top from "../img/icon/Molecule_light_top.png";
import img_6 from "../img/icon/Molecule_light_w_1.png";
import { useGetAlltopicQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import HTMLReactParser from "html-react-parser";
import Intro from "./Intro";
import Practicepaper from "./Practicepaper";
import { Link, useHistory } from "react-router-dom";

const Unit = (props) => {
  const history = useHistory();

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };
  const [introprops, setIntroprops] = useState();
  const topicdata = useGetAlltopicQuery(props.match.params.slug);
  const [openItem, setOpenItem] = useState(null); // Track the open accordion item

  useEffect(() => {
    if (topicdata.data && topicdata.data.length > 0) {
      const firstTopic = topicdata.data[0];
      setIntroprops(firstTopic.slug);
      console.log("Initial slug set:", firstTopic.slug); // Log slug here
    }
  }, [topicdata.data]);

  if (topicdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (topicdata.isError)
    return <h1>An error occurred: {topicdata.error.error}</h1>;

  return (
    <section className="questionbank-section">
      <div>
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].curriculaname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].subjname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(2)}>
                        {topicdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {topicdata.data[0].unitname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                  <h2>{topicdata.data[0].couresname} - Key Concept</h2>
                  <Row>
                    <Col sm={8}>
                      <p>{HTMLReactParser(topicdata.data[0].description)}</p>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img src={Key_top} className="d" />
                        <img src={img_6} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section>
        <Container>
          <Row>
            <Col sm={4}>
              <div className="accordion-container">
                <Accordion activeKey={openItem}>
                  {topicdata.data.map((topic) => (
                    <Accordion.Item
                      eventKey={topic.slug}
                      key={topic.slug}
                      className="custom-accordion-item"
                    >
                      <Accordion.Header
                        onClick={() => {
                          setOpenItem(
                            openItem === topic.slug ? null : topic.slug
                          );
                          setIntroprops(topic.slug);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={openItem === topic.slug}
                      >
                        {topic.name}
                      </Accordion.Header>
                      {openItem === topic.slug && (
                        <Accordion.Body>
                          <Practicepaper propspaperData={topic.slug} />
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Col>
            <Col sm={8} >
              <Intro propsintroData={introprops} />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </section>
  );
};

export default Unit;
