import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import img11 from "../img/comingsoon.png";
import img12 from "../img/404-mobile.png";
import { Link, useHistory } from "react-router-dom";

import "./error.css";

const Privacypolicy = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <>
      <div className="pagenotfoundheader">{/* <Header /> */}</div>
      <section className="Pagenotfound">
        <Container>
          <Row>
            <Col sm={4} style={{ display: "flex", alignItems: "center" }}>
              <div className="page-not-found-content">
                <h4>Coming Soon</h4>
                <p className="coming-soon-message">
                  We're working hard to bring you this content. Please check
                  back later!
                </p>
              </div>
            </Col>
            <Col sm={8}></Col>
          </Row>
        </Container>
      </section>
      <div>{/* <Footer /> */}</div>
    </>
  );
};

export default Privacypolicy;
