import React from 'react';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import Key_top from './img/icon/hide_eye_light.png';
import Key_top_w from './img/icon/hide_eye_light_w.png';

const TermsOfUse = () => {
  return (
    <section className="Terms-Of-Use">
      <Header />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="ap-heading mb-4">
              <Row className="flex-end mb-3">
                <Col sm={8}>
                  <h2 className="mb-3">Terms of Use</h2>
                </Col>
                <Col sm={4}>
                  <div className="key-concept-top-right text-center">
                    <img src={Key_top} className="d" alt="Key top" />
                    <img src={Key_top_w} alt="Key top white" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="Terms-Of-Use-content">
                    <p className="mb-4">
                      This website is operated by NUM8ERS INSTITUTE AND EDUCATION LLC ("NUM8ERS," "we," "us," "our") under the domain name learning.numb8ers.cloud (“the website”). We offer you the service of NUM8ERS on the condition that you agree to the following terms:
                    </p>

                    <h4 className="mb-4">Intellectual Property Rights</h4>
                    <p className="mb-4">
                      The material contained on the website is protected by copyright. You may use the website for personal and non-commercial purposes only. You acknowledge that the NUM8ERS website, the special technology used in connection with the website, NUM8ERS services, all software, material, information, communications, text, graphics, links, electronic art, animations, audio, video, photos, and other data available within the website (“NUM8ERS content”) are provided by NUM8ERS or third-party providers and are the copyrighted works of NUM8ERS and/or such third parties.
                    </p>

                    <p className="mb-4">
                      You may not copy, reproduce, publish, distribute, modify, create derivative works of, rent, lease, sell, transfer, display, transmit, compile or collect in a database, or in any manner commercially exploit any part of the NUM8ERS content or services unless expressly authorized by NUM8ERS or the relevant third-party owner.
                    </p>

                    <p className="mb-4">
                      All material on the NUM8ERS website is for individual use only, and account ownership may not be transferred or shared with others. You may not store any significant portion of NUM8ERS content or services in any form. NUM8ERS and/or third-party providers retain ownership of the NUM8ERS content, and you do not acquire any intellectual property rights in the content.
                    </p>

                    <h4 className="mb-4">Information Does Not Represent Professional Advice</h4>
                    <p className="mb-4">
                      The material provided on the website is for general information only and is not intended as professional advice. Before taking any action or entering into any transaction based on the website, you should make your own inquiries and seek independent advice tailored to your circumstances.
                    </p>

                    <h4 className="mb-4">Accuracy and Usefulness of Information</h4>
                    <p className="mb-4">
                      All material and information on the website are provided in good faith and are believed to be accurate as of the date of publication. However, NUM8ERS does not guarantee the accuracy, completeness, or currency of any material or information on the website or linked websites.
                    </p>

                    <p className="mb-4">
                      NUM8ERS will not be liable for damages arising from access to the website or the information contained therein. All warranties of any kind are excluded to the maximum extent permitted by law.
                    </p>

                    <h4 className="mb-4">Access</h4>
                    <p className="mb-4">
                      NUM8ERS may restrict or prevent access to the website for technical, security, or other reasons at its discretion. NUM8ERS will notify you of such restrictions as soon as practicable.
                    </p>

                    <h4 className="mb-4">Variation of These Terms</h4>
                    <p className="mb-4">
                      NUM8ERS may revise these Terms at any time by publishing updated Terms on the website. By continuing to use the website after updates, you accept the revised Terms.
                    </p>

                    <h4 className="mb-4">No Warranties or Representations</h4>
                    <p className="mb-4">
                      NUM8ERS does not guarantee that the website or content will be free from defects, viruses, or interruptions. To the maximum extent permissible by law, NUM8ERS is not liable for loss or damage arising from the use of the website or its content.
                    </p>

                    <h4 className="mb-4">Prices and Payments</h4>
                    <p className="mb-4">
                      All prices on the website are quoted in <strong> United States Dollars (USD)</strong>. Payments must be made in USD, and NUM8ERS accepts Visa, MasterCard, and other specified payment methods. Prices are subject to change without notice. Please confirm the price of a product or service before placing an order.
                    </p>

                    <h4 className="mb-4">Refund Policy</h4>
                    <p className="mb-4">
                      NUM8ERS offers a <strong>7-day refund policy </strong>, reviewed on an individual basis. Customers can request a refund within 7 days of purchase. Approval of refunds is at the sole discretion of NUM8ERS. The 7-day refund policy is not valid within 2 weeks of major exam dates. For purchase queries, please contact info@num8ers.com.
                    </p>

                    <h4 className="mb-4">Conduct Policy</h4>
                    <p className="mb-4">
                      By using this website, you agree to:
                    </p>
                    <ul className="mb-4">
                      <li>Not use the website in breach of laws or regulations.</li>
                      <li>Not use the website to transmit unsolicited content, impersonate others, or engage in unlawful activities.</li>
                      <li>Not upload content that violates intellectual property rights, is defamatory, or contains harmful code.</li>
                      <li>Not interfere with the operation or security of the website.</li>
                    </ul>
                    <p className="mb-4">
                      NUM8ERS reserves the right to remove or suspend accounts for violations of this policy.
                    </p>

                    <h4 className="mb-4">Links to Third-Party Websites</h4>
                    <p className="mb-4">
                      The website may contain links to external sites for convenience. NUM8ERS does not endorse or control the content of these third-party sites and is not liable for any damage arising from their use.
                    </p>

                    <h4 className="mb-4">Severability</h4>
                    <p className="mb-4">
                      If any part of these Terms is deemed invalid or unenforceable, the remaining provisions will remain valid and enforceable.
                    </p>

                    <h4 className="mb-4">General</h4>
                    <p className="mb-4">
                      The use of this website is governed by the laws of Dubai, UAE. You irrevocably submit to the non-exclusive jurisdiction of Dubai courts.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default TermsOfUse;
