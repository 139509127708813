import React from 'react';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import Key_top from './img/icon/hide_eye_light.png';
import Key_top_w from './img/icon/hide_eye_light_w.png';
import "./Terms.css"

const Privacypolicy = () => {
  return (
    <section className="privacypolicy-section">
      <Header />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="ap-heading mb-4">
              <Row className="flex-end mb-3">
                <Col sm={8}>
                  <h2 className="mb-3">Privacy Policy</h2>
                </Col>
                <Col sm={4}>
                  <div className="key-concept-top-right text-center">
                    <img src={Key_top} className="d" alt="Key top icon" />
                    <img src={Key_top_w} alt="Key top icon white" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="Privacy-Policy-content">
                    <h3 className="mb-4">Introduction</h3>
                    <p className="mb-4">
                      This website is operated by NUM8ERS INSTITUTE AND EDUCATION LLC ("NUM8ERS," "we," "us," "our"), under the domain name learning.numb8ers.cloud (“the website”). We are committed to protecting your online privacy and safety. This Privacy Policy explains what information we collect when you use NUM8ERS, how the information will be used, and how you can control the collection, correction, and/or deletion of information. This Privacy Policy applies only to information you provide us through the NUM8ERS website. Capitalized terms that are not defined in this Privacy Policy have the meaning given to them in our Terms of Use.
                    </p>

                    <h3 className="mb-4">What We Collect</h3>
                    <h5 className="mb-4">Personal Information</h5>
                    <p className="mb-4">
                      When you register to use the NUM8ERS website, you provide us with information about yourself, such as your name, username, and email address. You may also provide us with financial payment information, geolocation information, and Unique Device ID (UDID), all subject to the applicable privacy settings, if a purchase is made.
                    </p>

                    <h5 className="mb-4">Log File Information</h5>
                    <p className="mb-4">
                      When you use the Service, our servers automatically record certain log file information. These server logs may include information such as your web request, Internet Protocol (“IP”) address, browser type, referring/exit pages and URLs, number of clicks, how you interact with the Service, domain names, landing pages, pages viewed, and other such information.
                    </p>

                    <h5 className="mb-4">Clear Gifs Information</h5>
                    <p className="mb-4">
                      When you use the Service, we may employ clear gifs (also known as web beacons), which are used to track the usage patterns of our users anonymously. In addition, we may also use clear gifs in HTML-based emails sent to our users to track which emails are opened by recipients. This information is used to enable more accurate reporting, improve the effectiveness of our marketing, and make NUM8ERS better for our users.
                    </p>

                    <p className="mb-4">
                      NUM8ERS uses third-party analytics services, such as Google Analytics, to help understand the use of the Service. These third-party analytics services collect the information sent by your usage of the Service. NUM8ERS stores passwords in a secure database. This password information is encrypted and cannot be accessed or viewed by us.
                    </p>

                    <h3 className="mb-3">Your Rights</h3>
                    <ul className="mb-4">
                      <li>
                        Right to Erase: You can request your data to be completely erased from our servers. Please email our support team at info@num8ers.com, and your request will be processed within 48 hours.
                      </li>
                      <li>
                        Right to Rectification: You can correct inaccurate personal data at any time via the "My Account" link on the website or by contacting our support team.
                      </li>
                      <li>
                        Right to Restrict Processing: You can restrict or suppress the processing of your personal data by emailing us with your specific request.
                      </li>
                    </ul>

                    <h3 className="mb-3">How We Use Your Information</h3>
                    <ul className="mb-4">
                      <li>
                        To operate, maintain, and provide the features and functionality of the Service.
                      </li>
                      <li>
                        To send Service-related notices, receipts, and updates, including legal notices.
                      </li>
                      <li>
                        To send newsletters, feature changes, or special offers (which you can opt out of by clicking "unsubscribe" in emails).
                      </li>
                    </ul>

                    <h3 className="mb-3">What We Do With Your Information</h3>
                    <ul className="mb-4">
                      <li>
                        <strong>Personally Identifiable Information:</strong> NUM8ERS will not rent or sell your personally identifiable information to others. We may share your information with third parties solely to provide the Service to you. These third parties are bound by this Privacy Policy.
                      </li>
                      <li>
                        NUM8ERS may disclose personal information as required by law, subpoena, or to comply with legal processes; to protect our rights, investigate illegal activities, or enforce our Terms of Use; and to prevent harm, fraud, or abuse of our site.
                      </li>
                      <li>
                        We may share non-personally identifiable information (e.g., anonymous usage data) with third parties for analytics or improving the Service.
                      </li>
                    </ul>

                    <h3 className="mb-4">How We Protect Your Information</h3>
                    <p className="mb-4">
                      NUM8ERS protects your personal information through encryption, secure servers, and firewalls. However, no system is completely secure. We recommend choosing strong passwords and using different passwords for different services.
                    </p>

                    <h3 className="mb-4">Compromise of Personal Information</h3>
                    <p className="mb-4">
                      In case of a data breach, we will notify affected users promptly and take all necessary measures to secure your information.
                    </p>

                    <h3 className="mb-4">Children’s Privacy</h3>
                    <p className="mb-4">
                      NUM8ERS does not knowingly collect personal information from individuals under 13. If we learn of such collection, we will delete the information immediately.
                    </p>

                    <h3 className="mb-4">External Links</h3>
                    <p className="mb-4">
                      Our website may contain links to third-party sites. Once you leave our website, this Privacy Policy no longer applies. Please review the privacy policies of other websites you visit.
                    </p>

                    <h3 className="mb-4">Notifications and Updates</h3>
                    <p className="mb-4">
                      We may notify you about changes to this Privacy Policy via email, postal mail, or through updates on the website. Please check this page periodically for updates.
                    </p>

                    <h3 className="mb-4">Google Analytics and Remarketing Policy</h3>
                    <p className="mb-4">
                      NUM8ERS uses Google Analytics to collect anonymous demographic and usage data to better understand our audience. No visitor-specific data is used for Google Analytics, Google Ads, or remarketing purposes.
                    </p>

                    <h3 className="mb-4">Contact Information</h3>
                    <p className="mb-4">
                      If you have any questions about this Privacy Policy or your dealings with our website, please contact us at:
                    </p>
                    <p className="mb-4">
                      NUM8ERS INSTITUTE AND EDUCATION LLC
                      <br />
                      Head Office: Office 204, API Business Suites, Al Barsha 1, Dubai, UAE
                      <br />
                      Support Team: info@num8ers.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Privacypolicy;
