import React from 'react';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import Key_top from './img/icon/hide_eye_light.png';
import Key_top_w from './img/icon/hide_eye_light_w.png';

const PersonalInfo = () => {
  return (
    <section className="Personal-Info">
      <Header />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="ap-heading mb-4">
              <Row className="flex-end mb-3">
                <Col sm={8}>
                  <h2 className="mb-3">Personal Information Notice</h2>
                </Col>
                <Col sm={4}>
                  <div className="key-concept-top-right text-center">
                    <img src={Key_top} className="d" alt="Key top" />
                    <img src={Key_top_w} alt="Key top white" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="Personal-Info-content">
                    <p className="mb-4">
                      Last Updated: January 18, 2025
                    </p>

                    <p className="mb-4">
                      <strong>Introduction</strong>
                      <br />
                      At <strong>num8ers</strong>, we respect your privacy and are committed to handling your personal
                      information responsibly. This Notice explains our policy regarding the selling of personal information.
                    </p>

                    <p className="mb-4">
                      <strong>No Sale of Personal Information</strong>
                      <br />
                      We do not sell, trade, or rent your personal information to third parties. We may share your
                      information with trusted service providers, but only in accordance with our Privacy Policy and
                      applicable laws.
                    </p>

                    <p className="mb-4">
                      <strong>Why We Collect Personal Information</strong>
                      <br />
                      We collect personal information to provide our services, enhance user experiences, and comply
                      with legal obligations. All data collected is handled in accordance with our Privacy Policy.
                    </p>

                    <p className="mb-4">
                      <strong>Your Privacy Rights</strong>
                      <br />
                      Depending on your jurisdiction, you may have the right to request access to, or deletion of, your
                      personal information, or to withdraw consent for certain processing. For more details, please
                      refer to our Privacy Policy or contact us directly.
                    </p>

                    <p className="mb-4">
                      <strong>Updates to This Notice</strong>
                      <br />
                      We may update this Notice from time to time to reflect changes in our practices or legal
                      requirements. Any updates will be posted on this page with an updated date.
                    </p>

                    <p className="mb-4">
                      <strong>Contact Us</strong>
                      <br />
                      If you have any questions or concerns about our policies, please reach out to us:
                    </p>
                    <p className="mb-4">
                      <strong>Email:</strong> support@num8ers.com
                      <br />
                      <strong>Address:</strong> Al Barsha 1, Dubai, Dubai
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default PersonalInfo;
