import React, { useState, useEffect, useRef } from "react";
import { IoClose, IoSearch } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { useClickOutside } from "react-click-outside-hook";
import { useDebounce } from "../../hooks/debounceHook";
import MoonLoader from "react-spinners/MoonLoader";
import axios from "axios";
import { TvShow } from "../tvShow";
import "./search.css";

// Framer Motion variants for the main container
const containerVariants = {
  collapsed: {
    height: "50px",
    boxShadow: "none",
    transition: {
      type: "spring",
      damping: 18,
      stiffness: 180,
    },
  },
  expanded: {
    // The container will be 400px tall when expanded
    height: "400px",
    boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
    transition: {
      type: "spring",
      damping: 18,
      stiffness: 180,
    },
  },
};

export function SearchBar() {
  const [isExpanded, setExpanded] = useState(false);
  const [parentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef();

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [tvShows, setTvShows] = useState([]);
  const [noTvShows, setNoTvShows] = useState(false);

  // Check if results are empty
  const isEmpty = !tvShows || tvShows.length === 0;

  // Expand/Collapse
  const expandContainer = () => setExpanded(true);
  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery("");
    setLoading(false);
    setNoTvShows(false);
    setTvShows([]);
    if (inputRef.current) inputRef.current.value = "";
  };

  // Close if user clicks outside
  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  // Prepare the query URL
  const prepareSearchQuery = (query) => {
    const url = `https://num8ers.cloud/api/apsearchquestion/${query}`;
    return encodeURI(url);
  };

  // Perform the API request
  const searchTvShow = async () => {
    if (!searchQuery || searchQuery.trim() === "") return;

    setLoading(true);
    setNoTvShows(false);

    const URL = prepareSearchQuery(searchQuery);
    const response = await axios.get(URL).catch((err) => {
      console.log("Error: ", err);
    });

    if (response) {
      if (response.data && response.data.length === 0) setNoTvShows(true);
      setTvShows(response.data);
    }
    setLoading(false);
  };

  // Debounce the search to avoid too many requests
  useDebounce(searchQuery, 500, searchTvShow);

  // Handle input changes
  const changeHandler = (e) => {
    e.preventDefault();
    if (e.target.value.trim() === "") setNoTvShows(false);
    setSearchQuery(e.target.value);
  };

  return (
    <motion.div
      className="search-bar-container"
      ref={parentRef}
      variants={containerVariants}
      animate={isExpanded ? "expanded" : "collapsed"}
    >
        <form autoComplete="off" style={{ display: "flex", justifyContent: "center" }}>
      {/* Top section with input */}
      <div className="search-input-container">
        <span className="search-icon">
          <IoSearch />
        </span>
        <input
          className="search-input"
          placeholder="Find books, solutions, and more..."
          onFocus={expandContainer}
          ref={inputRef}
          value={searchQuery}
          onChange={changeHandler}
         
          autoComplete="new-password"
          autoCorrect="off"
          autoCapitalize="none"
        />

        <AnimatePresence>
          {isExpanded && (
            <motion.span
              className="close-icon"
              key="close-icon"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.2 }}
              onClick={collapseContainer}
            >
              <IoClose />
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      </form>

      {/* The horizontal line (separator) */}
      <AnimatePresence>
        {isExpanded && (
          <motion.span
            className="line-separator"
            key="line-separator"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          />
        )}
      </AnimatePresence>

      {/* The content (results, loading, etc.) */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="search-content"
            key="search-content"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {isLoading && (
              <div className="loading-wrapper">
                <MoonLoader loading color="#000" size={20} />
              </div>
            )}

            {!isLoading && isEmpty && !noTvShows && (
              <div className="loading-wrapper">
                <span className="warning-message">Start typing to Search</span>
              </div>
            )}

            {!isLoading && noTvShows && (
              <div className="loading-wrapper">
                <span className="warning-message">Question Not Found!</span>
              </div>
            )}

            {/* Render search results if they exist */}
            {!isLoading && !isEmpty && (
              <>
                {tvShows.map((question) => (
                  <TvShow
                    className="search-bar-text"
                    key={question.id}
                    name={question.name}
                  />
                ))}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
