import React, { useState, useEffect } from "react";
import Isotope from "isotope-layout";
import "./Unit.css";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
// REMOVED: Container, Row, Col from "react-bootstrap" imports
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion"; // Remove if not used
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Collapse from "react-bootstrap/Collapse"; // Remove if not used
import ProgressBar from "react-bootstrap/ProgressBar"; // Remove if not used
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import img_1 from "../img/icon/Arrow_right_light.png";
import img_2 from "../img/question-img-1.png";
import img_3 from "../img/icon/Video_light.png";
import img_4 from "../img/book-img.png";
import img_5 from "../img/icon/Arrow_right_light_w.png";
import img_6 from "../img/paper-photo.png";
import img_7 from "../img/for.png";
import EasyIcon from "../img/icon/EasyIcon.png";
import MediumIcon from "../img/icon/MediumIcon.png";
import HardIcon from "../img/icon/HardIcon.png";
import FormulaIcon from "../img/FormulaIcon.png";
import MarkSchemeIcon from "../img/MarkSchemeIcon.png";
import SimilarQuestionIcon from "../img/SimilarQuestionIcon.png";
import VideoAnswerIcon from "../img/VideoAnswerIcon.png";

import { useGetAlltopicQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Questionbankpaper from "./Questionbankpaper";
import Papercard from "./Papercard";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
import BGRight2 from "../img/Bg Right 2.png"; // background image
import FormulasIcon from "../img/icon/FormulasIcon.png";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";
import { AiOutlineExpand, AiOutlineCompress } from "react-icons/ai";
import { useSelector } from "react-redux";
import MathJaxRenderer from "../MathJaxRenderer";

const Unit = (props) => {
  const history = useHistory();

  const purchasedCourses = useSelector((state) => state.user.purchasedCourses);

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };

  const topicdata = useGetAlltopicQuery(props.match.params.slug);

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [iso] = useState(null);
  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cardprops, setCardprops] = useState();
  const [fetchedData, setfetchedData] = useState(null);
  const [questions, setQuestions] = useState(null);

  // Modal states
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [currentFormulaImage, setCurrentFormulaImage] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoURL, setCurrentVideoURL] = useState(null);
  const [showMarkSchemeModal, setShowMarkSchemeModal] = useState(false);
  const [currentMarkSchemeImage, setCurrentMarkSchemeImage] = useState(null);
  const [currentMarkSchemeHTML, setCurrentMarkSchemeHTML] = useState(null);

  // Topic & question filtering states
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedDifficulty, setSelectedDifficulty] = useState("All"); // 'Easy' | 'Medium' | 'Hard' | 'All'

  // Fullscreen state
  const [isFullscreen, setIsFullscreen] = useState(false);

  const location = useLocation();

  // Helper function to convert YouTube URLs to embed URLs
  const getEmbedUrl = (url) => {
    if (!url) return "";
    try {
      const parsedUrl = new URL(url);
      // Check if it's a YouTube watch URL
      if (
        parsedUrl.hostname.includes("youtube.com") &&
        parsedUrl.pathname === "/watch"
      ) {
        const videoId = parsedUrl.searchParams.get("v");
        if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      }
      // Check if it's a shortened YouTube URL (youtu.be)
      if (parsedUrl.hostname === "youtu.be") {
        const videoId = parsedUrl.pathname.slice(1); // remove leading '/'
        if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
    // Fallback: return the original URL if it doesn't match our criteria
    return url;
  };

  /* Mark Scheme */
  const handleOpenMarkSchemeModal = (htmlString) => {
    console.log("Mark Scheme HTML string:", htmlString);

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    // Store the original HTML string
    setCurrentMarkSchemeHTML(htmlString);

    setShowMarkSchemeModal(true);
  };
  const handleCloseMarkSchemeModal = () => {
    setShowMarkSchemeModal(false);
    setCurrentMarkSchemeImage(null);
  };

  /* Video Answer */
  const handleOpenVideoModal = (videoURL) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    console.log("Received video URL:", videoURL);
    console.log("Converted embed URL:", getEmbedUrl(videoURL));
    setCurrentVideoURL(videoURL);
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideoURL(null);
  };

  /* Question Navigation */
  const handleQuestionClick = (index) => {
    setSelectedQuestionIndex(index);
    const questionElement = document.getElementById(
      `questions-container-${index}`
    );
    if (questionElement) {
      questionElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  /* Filtered Questions by Difficulty */
  const filteredQuestions = questions?.filter((question, index) => {
    question.originalIndex = index; // store the original index
    if (selectedDifficulty === "All") return true;
    return question.status === selectedDifficulty;
  });

  /* Formula Modal */
  const handleOpenFormulaModal = (formulaImage) => {
    setCurrentFormulaImage(formulaImage);
    setShowFormulaModal(true);
  };

  const handleCloseFormulaModal = () => {
    setShowFormulaModal(false);
    setCurrentFormulaImage(null);
  };

  const extractImageUrls = (htmlString) => {
    const imgTags = htmlString.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
    if (!imgTags) return [];
    const srcRegex = /src="([^"]*)"/;
    return imgTags
      .map((imgTag) => {
        const match = imgTag.match(srcRegex);
        return match ? match[1] : null;
      })
      .filter(Boolean);
  };

  /* Fetch questions from API */
  const fetchQuestions = async (topicSlug) => {
    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/questionbankquestion/${topicSlug}`
      );
      setQuestions(response.data);
    } catch (err) {
      console.error("Error fetching questions:", err);
      setError(err.message);
    }
  };

  /* Fetch topics (and general data) from API */
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/aptopicdata/${props.match.params.slug}`
      );
      setfetchedData(response.data);
      setCardprops(response.data[0].slug); // default to first returned slug
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  /* Fullscreen toggling */
  const toggleFullscreen = (index) => {
    const container = document.getElementById(`questions-container-${index}`);
    if (container) {
      if (!document.fullscreenElement) {
        container.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable fullscreen: ${err.message}`
          );
        });
        setIsFullscreen(true);
      } else {
        document.exitFullscreen().catch((err) => {
          console.error(`Error attempting to exit fullscreen: ${err.message}`);
        });
        setIsFullscreen(false);
      }
    } else {
      console.error("Container not found: Cannot toggle fullscreen");
    }
  };

  /* Initial fetch */
  useEffect(() => {
    fetchData();
  }, [props.match.params.slug]);

  useEffect(() => {
    if (topicdata.data && topicdata.data.length > 0) {
      // Possibly a topic from location.state
      const topicSlugFromState = location.state?.selectedTopicSlug;
      const firstTopic = topicSlugFromState
        ? topicdata.data.find((topic) => topic.slug === topicSlugFromState)
        : topicdata.data[0];

      if (firstTopic) {
        setSelectedTopic(firstTopic.id);
        setCardprops(firstTopic.slug);
        fetchQuestions(firstTopic.slug);
      }
    }
  }, [topicdata.data, location.state]);

  if (topicdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (topicdata.isError)
    return <h1>An error occurred {topicdata.error.error}</h1>;

  /* Check user course access (based on purchasedCourses) */
  const isCourseAccessible = (courseId) => {
    // Find the matching record in purchasedCourses
    const course = purchasedCourses.find((c) => c.course_id === courseId);
    if (!course) return false; // not purchased at all

    // Check if it's still within the subscription end date
    const now = new Date().getTime() / 1000; // current time in Unix seconds
    return course.end_date >= now;
  };

  const neededCourseId = topicdata.data[0]?.apcourse_id; // use apcourse_id

  // If the user does NOT have access, redirect
  if (!isCourseAccessible(neededCourseId)) {
    history.push("/payment");
    return null; // stop rendering, so no further UI is shown
  }

  return (
    <div className="questionbank-unit">
      {/* Top Section & Header */}
      <div className="top-header-quetion">
        <Header />
        <section className="ap">
          {/* Replaced <Container> <Row> <Col> with pure divs */}
          <div className="my-container">
            <div className="my-row pb-60">
              <div className="my-col-sm-12">
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].curriculaname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].subjname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(2)}>
                        {topicdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {topicdata.data[0].unitname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>

              <div className="my-col-sm-12">
                <div className="qb-heading">
                  <h2>{topicdata.data[0].couresname} - Question Bank</h2>
                  {/* Another row replaced with plain divs */}
                  <div className="my-row">
                    <div className="my-col-sm-8">
                      <MathJaxRenderer>
                        <p>
                          {HTMLReactParser(topicdata.data[0].couresdescription)}
                        </p>
                      </MathJaxRenderer>
                    </div>

                    <div className="my-col-sm-4">
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" alt="Quiz Icon 1" />
                        <img src={qu_top_w} alt="Quiz Icon 2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Main Section */}
      <section className="unit-page pt-60 pb-120">
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
        <div className="my-row">
          {/* Sidebar with Topics (replacing <Col sm={3} />) */}
          <div className="my-col-sm-3 sticky-column">
            <h3 className="unit-title">{topicdata.data[0].unitname}</h3>
            {topicdata.data.map((topic) => (
              <Button
                key={topic.id}
                onClick={() => {
                  setCardprops(topic.slug);
                  fetchQuestions(topic.slug);
                  setSelectedTopic(topic.id);
                }}
                variant="outline-primary"
                className={`topic-button ${
                  selectedTopic === topic.id ? "selected" : ""
                }`}
              >
                <span className="topic-button-text">{topic.name}</span>
                <FaChevronRight className="topic-button-icon" />
              </Button>
            ))}
          </div>

          {/* Questions & Filters (replacing <Col sm={8} />) */}
          <div className="my-col-sm-8">
            {/* Difficulty Filters (replacing <Col sm={11} /> if needed) */}
            <div className="my-col-sm-11">
              <div className="difficulty-filters">
                <Button
                  onClick={() => setSelectedDifficulty("Easy")}
                  className={selectedDifficulty === "Easy" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={EasyIcon} alt="Easy" />
                  Easy
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Medium")}
                  className={selectedDifficulty === "Medium" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={MediumIcon} alt="Medium" />
                  Medium
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Hard")}
                  className={selectedDifficulty === "Hard" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={HardIcon} alt="Hard" />
                  Hard
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("All")}
                  className={selectedDifficulty === "All" ? "active" : ""}
                  variant="outline-primary"
                >
                  All
                </Button>
              </div>
            </div>

            {filteredQuestions && filteredQuestions.length > 0 ? (
              <div>
                {/* Question List Scroll */}
                <div className="question-list-scroll">
                  {filteredQuestions.map((question, index) => (
                    <div
                      key={index}
                      onClick={() => handleQuestionClick(index)}
                      className={`question-list-item ${
                        selectedQuestionIndex === question.originalIndex
                          ? "active"
                          : ""
                      }`}
                    >
                      Question {question.originalIndex + 1}
                    </div>
                  ))}
                </div>

                {/* Render Each Question */}
                {filteredQuestions.map((question, index) => (
                  <div
                    key={question.originalIndex}
                    className="questions-container"
                    id={`questions-container-${index}`}
                  >
                    {/* Question Header */}
                    <div className="title-container">
                      <h3 className="h3-title">
                        Question {question.originalIndex + 1}
                      </h3>

                      {/* Difficulty Icon */}
                      {question.status === "Easy" && (
                        <img
                          src={EasyIcon}
                          alt="Easy Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Medium" && (
                        <img
                          src={MediumIcon}
                          alt="Medium Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Hard" && (
                        <img
                          src={HardIcon}
                          alt="Hard Icon"
                          className="status-icon"
                        />
                      )}
                    </div>

                    {/* Formula Button */}
                    {question.formula && (
                      <Button
                        variant="link"
                        onClick={() => handleOpenFormulaModal(question.formula)}
                        className="formulaButton"
                      >
                        <img
                          src={FormulaIcon}
                          alt="Formulas Icon"
                          className="formulaIcon"
                        />
                        <span className="formulatext">Formulas</span>
                      </Button>
                    )}

                    {/* Question Content */}
                    <div className="question-photo">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.description.replace(
                            /<img /g,
                            '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                          ),
                        }}
                      />
                    </div>

                    {/* Additional Buttons */}
                    <div className="additional-buttons">
                      <Button
                        variant="link"
                        onClick={() =>
                          handleOpenMarkSchemeModal(question.photo)
                        }
                        className="markscheme-button"
                      >
                        <img
                          src={MarkSchemeIcon}
                          alt="Mark Scheme Icon"
                          className="markscheme-icon"
                        />
                        Mark Scheme
                      </Button>

                      <Button
                        variant="link"
                        onClick={() => handleOpenVideoModal(question.video)}
                        className="videoanswer-button"
                      >
                        <img
                          src={VideoAnswerIcon}
                          alt="Video Answer Icon"
                          className="videoanswer-icon"
                        />
                        Video Answer
                      </Button>

                      <Button
                        variant="link"
                        onClick={() =>
                          history.push(`/Similar-Question/${question.slug}/`)
                        }
                        className="similarquestion-button"
                      >
                        <img
                          src={SimilarQuestionIcon}
                          alt="Similar Question Icon"
                          className="similarquestion-icon"
                        />
                        More Similar Questions
                      </Button>

                      <Button
                        variant="link"
                        onClick={() => toggleFullscreen(index)}
                        className="fullscreen-button"
                        title={
                          isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"
                        }
                      >
                        {isFullscreen ? (
                          <AiOutlineCompress size={24} />
                        ) : (
                          <AiOutlineExpand size={24} />
                        )}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No questions found with the current filter =(</p>
            )}
          </div>
        </div>
      </section>
      {/* Formula Modal */}
      <Modal
        show={showFormulaModal}
        onHide={handleCloseFormulaModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFormulaImage && (
            <iframe
              src={currentFormulaImage}
              style={{ width: "100%", height: "600px" }}
              title="Formula PDF"
            />
          )}
        </Modal.Body>
      </Modal>
      {/* Video Modal */}
      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentVideoURL ? (
            <div className="video-container">
              <iframe
                src={getEmbedUrl(currentVideoURL)}
                style={{ width: "100%", height: "400px" }}
                title="Video Answer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <p>No video available.</p>
          )}
        </Modal.Body>
      </Modal>
      {/* Mark Scheme Modal */}
      <Modal
        show={showMarkSchemeModal}
        onHide={handleCloseMarkSchemeModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentMarkSchemeHTML ? (
            <MathJaxRenderer>
              {HTMLReactParser(currentMarkSchemeHTML, {
                replace: (domNode, index) => {
                  // Check if the node is an <img> tag.
                  if (domNode.type === "tag" && domNode.name === "img") {
                    return (
                      <img
                        key={index}
                        src={domNode.attribs.src}
                        alt={
                          domNode.attribs.alt ||
                          `Mark Scheme image ${index + 1}`
                        }
                        style={{
                          width: "100%",
                          height: "auto",
                          marginBottom: "10px",
                        }}
                      />
                    );
                  }
                },
              })}
            </MathJaxRenderer>
          ) : (
            <p>No Mark Scheme available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default Unit;
