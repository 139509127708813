import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Load your Stripe publishable key
const stripePromise = loadStripe("pk_live_51LpV0JDttShgPLSX4wDqvrfkYCnzTSFr48dbAXHWltgEeV132nMMElADnSnRH9YQ8BPtrP9gsE5eYq81X2n313qO00kAOfwwMy");

const Checkout = () => {
  const handleCheckout = async () => {
    const stripe = await stripePromise;

    // Redirect to the Stripe-hosted Checkout page
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1QhC3FDttShgPLSXYN2dWboX", // Replace with your Stripe Price ID
          quantity: 1,
        },
      ],
      mode: "payment", // Use 'subscription' for recurring payments
      successUrl: `${window.location.origin}/success`, // Replace with your success URL
      cancelUrl: `${window.location.origin}/cancel`, // Replace with your cancel URL
    });

    if (error) {
      console.error("Error redirecting to Checkout:", error.message);
    }
  };

  return (
    <section className="checkout-page">
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <h2>Checkout</h2>
            <p>Click the button below to complete your purchase securely via Stripe.</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={6} className="text-center">
            <Button onClick={handleCheckout} className="checkout-button">
              Pay Now
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Checkout;
