import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./contactus.css"; // Our custom styling (including checkboxes)
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import { Col, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import BGRight1 from "../img/Bg Right 1.png"; 
import BGRight2 from "../img/Bg Right 2.png"; 
import BGMiddle from "../img/BG Middle.png"; 

const Contactus = () => {
  // Set up state for the form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    updates: false,
  });

  // Update state when input values change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission using EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace the following values with your EmailJS credentials:
    const serviceID = "service_7z1kncr";
    const templateID = "template_tfcxkwz";
    const userID = "Q06eeOp1blOS1KBOY"; // Also known as Public Key in EmailJS dashboard

    // Send the email via EmailJS
    emailjs.send(serviceID, templateID, formData, userID)
      .then((result) => {
        console.log("Email sent:", result.text);
        alert("Message sent successfully!");
        // Reset form
        setFormData({
          name: "",
          email: "",
          message: "",
          updates: false,
        });
      }, (error) => {
        console.error("Email error:", error.text);
        alert("Error sending message. Please try again.");
      });
  };

  return (
    <>
      <img
        src={BGMiddle}
        alt="Splatter"
        className="splatter-image-middle-contact"
      />
      <img
        src={BGRight1}
        alt="Splatter"
        className="splatter-image-right1-contact"
      />
      <img
        src={BGRight2}
        alt="Splatter"
        className="splatter-image-right2-contact"
      />
      <Header />

      <section className="contactus-page">
        <Container className="contactus-page-content">
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="contactus-form-wrapper">
                <h2 className="mb-4">
                  Contact <span>us</span>
                </h2>
                <div className="content-page-from">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicMessage">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCheckboxUpdates" className="mb-3 my-checkbox">
                      <Form.Check
                        type="checkbox"
                        label="I would like to receive news updates."
                        name="updates"
                        checked={formData.updates}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>
    </>
  );
};

export default Contactus;
