import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "./Footer";
import logo_d from "../img/logo/black-logo.png";
import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";
import "./VerifyEmail.css";

const VerifyEmail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const verificationMessage =
    "Please check your inbox to verify your email address. Follow the link in the email to complete your registration.";

  return (
    <>
      <Header />
      <section className="verify-email-section">
        {/* Background images for a decorative effect */}
        <img src={BGRight1} alt="Decorative" className="bg-right-1" />
        <img src={BGRight2} alt="Decorative" className="bg-right-2" />
        

        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="verify-email-card">
                <div className="logo-container">
                  <Link to="/">
                    <img src={logo_d} alt="Logo" className="logo" />
                  </Link>
                </div>
                <h2>Verify Your Email</h2>
                <p>{verificationMessage}</p>
                <Button as={Link} to="/login" className="btn-verify">
                  Go to Login
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default VerifyEmail;
