import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./VerifyEmailModal.css";

// Example: Adjust this import to your actual logo file
import logo_d from "../img/logo/black-logo.png";

const VerifyEmailModal = ({ show, onHide, message }) => {
  return (
    <Modal
      className="custom-modal"
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
    >
      {/* Header with just the logo & close button */}
      <Modal.Header closeButton>
        <img src={logo_d} alt="Numbers Logo" className="modal-logo" />
      </Modal.Header>

      {/* Body with the heading + the verification message */}
      <Modal.Body>
        <h2 className="modal-title-body">Email Verification</h2>
        {message ? (
          <p>{message}</p>
        ) : (
          <p>Please check your inbox to verify your email.</p>
        )}
      </Modal.Body>

      {/* Footer with the OK button */}
      <Modal.Footer>
      <Button className="custom-ok-button" onClick={onHide}>
  OK
</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyEmailModal;
