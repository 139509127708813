import React from 'react';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import Key_top from './img/icon/hide_eye_light.png';
import Key_top_w from './img/icon/hide_eye_light_w.png';

const CookiesNotice = () => {
  return (
    <section className="Cookies-Notice">
      <Header />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="ap-heading mb-4">
              <Row className="flex-end mb-3">
                <Col sm={8}>
                  <h2 className="mb-3">Cookies Notice</h2>
                </Col>
                <Col sm={4}>
                  <div className="key-concept-top-right text-center">
                    <img src={Key_top} className="d" alt="Key top" />
                    <img src={Key_top_w} alt="Key top white" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="Cookies-Notice-content">
                    <p className="mb-4">
                      Last Updated: January 18, 2025
                    </p>

                    <p className="mb-4">
                      <strong>Introduction</strong>
                      <br />
                      This Cookies Notice explains how we use cookies and similar technologies on our website. By
                      continuing to browse or use our site, you agree to the use of cookies as described in this
                      notice.
                    </p>

                    <p className="mb-3">
                      <strong>What Are Cookies?</strong>
                      <br />
                      Cookies are small text files that are stored on your device when you visit a website. They
                      help the website remember your preferences and improve your user experience on subsequent visits.
                    </p>

                    <p className="mb-3">
                      <strong>Types of Cookies We Use</strong>
                      <br />
                      We may use both session cookies (which expire once you close your browser) and persistent cookies
                      (which stay on your device until you delete them). These include:
                    </p>
                    <ul className="mb-4">
                      <li>
                        <strong>Essential Cookies:</strong> Necessary for our site to function properly and securely.
                      </li>
                      <li>
                        <strong>Analytics Cookies:</strong> Used to collect information about how visitors use our site,
                        such as which pages are visited most often.
                      </li>
                      <li>
                        <strong>Functionality Cookies:</strong> Allow us to remember choices you make and tailor the site
                        to provide enhanced features and content for you.
                      </li>
                    </ul>

                    <p className="mb-4">
                      <strong>How to Manage Cookies</strong>
                      <br />
                      You can control or delete cookies through your browser settings at any time. However, disabling
                      certain cookies may affect the functionality of the website.
                    </p>

                    <p className="mb-4">
                      <strong>Changes to This Cookies Notice</strong>
                      <br />
                      We may update this Cookies Notice periodically to reflect changes in our practices or applicable
                      laws. We encourage you to review it occasionally to stay informed about how we use cookies.
                    </p>

                    <p className="mb-4">
                      <strong>Contact Us</strong>
                      <br />
                      If you have questions about our use of cookies, please contact us at:
                    </p>
                    <p className="mb-4">
                      <strong>Email:</strong> support@num8ers.com
                      <br />
                      <strong>Address:</strong> Al Barsha 1, Dubai, Dubai
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default CookiesNotice;
