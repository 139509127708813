import React, { useState, useEffect } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "./Footer";
import { useRegisterUserMutation } from "../services/aplms";
import { storeToken } from "../services/LocalStorageService";

import logo_d from "../img/logo/black-logo.png";
import logo_m_d from "../img/icon/mobile-view-logo.png";
import logo_w from "../img/logo/white-logo.png";
import img_1 from "../img/icon/Social g.png";
import img_2 from "../img/icon/Social l.png";
import img_3 from "../img/icon/Social f.png";

import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";

import MailIcon from "../img/icon/MailIcon.png";
import PassIcon from "../img/icon/PasswordIcon.png";
import GoogleIcon from "../img/icon/GoogleIcon.png";
import SignUpImage from "../img/icon/SignUp Image.png";
import LockIcon from "../img/icon/LockIcon.png";
import { useGoogleLoginMutation } from "../services/aplms";

import { FaLock } from "react-icons/fa";

import Modal from "react-bootstrap/Modal";

import VerifyEmailModal from "./VerifyEmailModal";

const Sinup = () => {
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState("");

  useEffect(() => {
    // Get the token from the URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      // Save token to localStorage
      localStorage.setItem("auth_token", token);
      // Optional: Remove token from URL after saving
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  window.scrollTo(0, 0);
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const history = useHistory();
  const [registerUser] = useRegisterUserMutation();

  const [googleLogin, { isLoading, isSuccess, data, error: googleError }] =
    useGoogleLoginMutation();

  const handleGoogleSignIn = async () => {
    try {
      const response = await googleLogin();
      console.log("Login success:", response);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const actualData = {
      email: formData.get("email"),
      fname: formData.get("fname"),
      lname: formData.get("lname"),
      country: formData.get("country"),
      phone: formData.get("phone"),
      password: formData.get("password"),
      confirm_password: formData.get("confirm_password"),
    };

    // Basic validation
    if (actualData.email && actualData.password && actualData.confirm_password) {
      if (actualData.password === actualData.confirm_password) {
        // Attempt registration
        const res = await registerUser(actualData);
        console.log("Server response:", res);

        if (res.data && res.data.status === "success") {
          console.log("Registration successful:", res.data);
          history.push("/Verify-Email");
        } else if (res.data && res.data.status === "failed") {
          console.log("Registration failed:", res.data);
          setError({ status: true, msg: res.data.message, type: "error" });
        } else if (res.error) {
          console.log("Registration error:", res.error);
          if (res.error.data) {
            console.log("Error data:", res.error.data);
          }
          // Extract error message from the error response structure.
          let errorMessage = "An unexpected error occurred.";
          if (res.error.data) {
            // Check if there is an email error
            if (
              res.error.data.errors &&
              res.error.data.errors.email &&
              Array.isArray(res.error.data.errors.email)
            ) {
              errorMessage = res.error.data.errors.email[0];
            } else if (res.error.data.message) {
              errorMessage = res.error.data.message;
            }
          }

          setError({ status: true, msg: errorMessage, type: "error" });
        } else {
          setError({
            status: true,
            msg: "An unexpected error occurred.",
            type: "error",
          });
        }
      } else {
        setError({
          status: true,
          msg: "Password and Confirm Password don't match",
          type: "error",
        });
      }
    } else {
      setError({
        status: true,
        msg: "All Fields are Required",
        type: "error",
      });
    }
  };

  return (
    <>
      {/* Desktop View */}
      <section className="signup-section">
        <Header />

        <VerifyEmailModal
          show={showVerifyModal}
          onHide={() => setShowVerifyModal(false)}
          message={verifyMessage}
        />
        <img
          src={BGRight1}
          alt="Splatter"
          className="splatter-image-right-1-login"
        />
        <img
          src={BGRight2}
          alt="Splatter"
          className="splatter-image-right-2-login"
        />
        <img
          src={BGMiddle}
          alt="Splatter"
          className="splatter-image-middle-login"
        />
        <div style={{ height: "100px" }}></div>
        <Container>
          <Row className="login-from">
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={SignUpImage} className="drack" alt="Sign Up" />
                </Link>
                <Link to="/">
                  <img src={SignUpImage} className="lite" alt="Sign Up" />
                </Link>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <h4>Sign Up</h4>
                {error.status && (
                  <div className={`error-message show ${error.type}`}>
                    {error.msg}
                  </div>
                )}
                <p className="from-bottom-text">
                  Access to 80+ hours of courses, tutorials, and source files.
                </p>

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {/* Email */}
                  <Form.Group
                    className="mb-4 email-input-group"
                    controlId="formBasicEmail"
                  >
                    <div className="input-icon-wrapper">
                      <img
                        src={MailIcon}
                        alt="Mail Icon"
                        className="input-icon"
                      />
                      <input
                        type="email"
                        className="custom-input email-input"
                        placeholder="Email Address"
                        name="email"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      />
                    </div>
                  </Form.Group>

                  {/* Password */}
                  <Form.Group
                    className="mb-2 password-input-group"
                    controlId="formBasicPassword"
                  >
                    <div className="mb-4 input-icon-wrapper">
                      <FaLock
                        className="input-icon-lock"
                        style={{ fontSize: "22px", color: "white" }}
                      />
                      <input
                        type="password"
                        className="custom-input password-input"
                        placeholder="Password"
                        name="password"
                        minLength="8"
                        required
                        pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$"
                        title="Must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
                      />
                    </div>
                  </Form.Group>

                  {/* Confirm Password */}
                  <Form.Group
                    className="mb-2 password-input-group"
                    controlId="formBasicConfirmPassword"
                  >
                    <div className="input-icon-wrapper">
                      <FaLock
                        className="input-icon-lock"
                        style={{ fontSize: "22px", color: "white" }}
                      />
                      <input
                        type="password"
                        className="custom-input password-input"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        minLength="8"
                        required
                        pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$"
                        title="Must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
                      />
                    </div>
                  </Form.Group>

                  <Button className="Signup-Button" type="submit">
                    Sign Up
                  </Button>
                  <p className="from-bottom-text">
                    By clicking on Login, you agree to our{" "}
                    <a
                      href="/TermsOfUse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-button-style"
                    >
                      Terms of service
                    </a>{" "}
                    and{" "}
                    <a
                      href="/Privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-button-style"
                    >
                      Privacy policy
                    </a>
                    .
                  </p>
                  <p className="forgot-password-text">
                    Already have an account?{" "}
                    <Link to="/login" className="login-button-style">
                      Sign In
                    </Link>
                  </p>

                  <p className="forgot-password-text">
                    Forgot Password?{" "}
                    <Link to="/forgot" className="login-button-style">
                      Forgot Password
                    </Link>
                  </p>

                  <h5 className="continue-with-text">Or continue with...</h5>

                  <div
                    className="continue-with-google"
                    onClick={() => {
                      window.location.href =
                        "https://num8ers.cloud/api/auth/google";
                    }}
                  >
                    <img
                      src={GoogleIcon}
                      alt="Google Icon"
                      className="google-icon"
                    />
                    <h4 className="google-text">Google</h4>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>

      {/* Mobile View */}
      <section className="login mobile-view">
        <Header />
        <Container>
          <Row>
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_m_d} className="drack" alt="Mobile Logo" />
                </Link>
                <Link to="/">
                  <img src={logo_m_d} className="lite-logo" alt="Mobile Logo" />
                </Link>
              </div>
              <h4 className="signup-page-mobile-view-top-header mt-30 mb-30">
                Discover. Dive. Dominate.
              </h4>
            </Col>
            <Col sm={6}>
              <div className="login-from">
                {error.status ? (
                  <p className="p_alrat" severity={error.type}>
                    {error.msg}
                  </p>
                ) : null}

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {/* Email */}
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                  </Form.Group>

                  {/* Password */}
                  <Form.Group className="mb-8" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                    />
                    <div className="password">
                      <Link to="/forgot" className="login-button-style">
                        Forgot Password?
                      </Link>
                    </div>
                  </Form.Group>

                  {/* Confirm Password */}
                  <Form.Group
                    className="mb-4"
                    controlId="formBasicConfirmPassword"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      name="confirm_password"
                      type="password"
                      id="confirm_password"
                      placeholder="Confirm Password"
                    />
                  </Form.Group>

                  <Form.Group className="mb-5" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      className="form-check-input"
                      label="Remember me"
                    />
                  </Form.Group>

                  <Row>
                    <Col sm={12}>
                      <p className="or-login-text">or Login with</p>
                    </Col>
                    <div className="or-login-button">
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_1} alt="Google" /> Google
                        </Button>
                      </div>
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_2} alt="LinkedIn" /> LinkedIn
                        </Button>
                      </div>
                      <div className="login-button">
                        <Button>
                          <img src={img_3} alt="Facebook" /> Facebook
                        </Button>
                      </div>
                    </div>
                  </Row>
                  <Button variant="primary" type="submit">
                    Start Learning
                  </Button>
                  <p className="from-bottom-text">
                    By continuing, you accept our <span>Terms of Use</span>, our
                    <span>Privacy Policy</span> and that your data is stored in
                    the cloud.
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Sinup;
