// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { aplmsApi } from '../services/aplms';
import userReducer from '../features/userSlice';
import authReducer from '../features/authSlice';

// 1) Import redux-persist libs
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// 2) Create your root reducer by combining slices
const rootReducer = combineReducers({
  [aplmsApi.reducerPath]: aplmsApi.reducer,
  user: userReducer,
  auth: authReducer,
});

// 3) Configure which slices you want to persist
const persistConfig = {
  key: 'root',           // key for localStorage
  storage,               // localStorage (web)
  whitelist: ['user', 'auth'], // persist these slices only
  // or blacklist: ['something'] to exclude
};

// 4) Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 5) Create the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // often need to disable this for redux-persist
    }).concat(aplmsApi.middleware),
});

// 6) Create a persistor to “re-hydrate” the store
export const persistor = persistStore(store);

// 7) Optional: Setup listeners for RTK Query
setupListeners(store.dispatch);
