import React from 'react';
import './brand.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import img_1 from '../img/ib.png';
import img_2 from '../img/ap.png';
import img_3 from '../img/pe.png';
import img_4 from '../img/gcse.png';

const Brand = () => {
  return (
    <>
      <section className="brend desktop-view">
        <Container>
          <Row>
            <Col sm={3}>
              <div className="brand-box">
                <img src={img_1} />
                <p>International Baccalaureate</p>
              </div>
            </Col>
            <Col sm={3}>
              <div className="brand-box">
                <img src={img_2} />
                <p>College Board </p>
              </div>
            </Col>
            <Col sm={3}>
              <div className="brand-box-2">
                <img src={img_3} />
              </div>
            </Col>
            <Col sm={3}>
              <div className="brand-box-2">
                <img src={img_4} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
  
    </>
  );
};

export default Brand;
