import React from "react";
import "./hero.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Hero_img from "../img/hero.png";

import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image
import BGBottomRight from "../img/Bg Bottom Right.png"; // Import the background image
import { NavLink, Link } from "react-router-dom";
import { useGetAllcurriculumQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import SquaresArt from "./SquaresArt";
import Wave from "../img/wave1.png";
import CurriculumSquares from "./CurriculumSquares";

const Hero = () => {
  const curriculum = useGetAllcurriculumQuery();
  
  if (curriculum.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (curriculum.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (curriculum.isError) return <h1>data not found </h1>;

  return (
    
    <>
      <section className="hero-section "
        style={{
          
        }}
      >
        <div className="cirriculum-squares-background">
          <CurriculumSquares curriculumData={curriculum.data.slice(0, 5)} />
        </div>

        <Container>
          <Row>
            <div className="squares-art-background">
              <SquaresArt />
            </div>
            <Col sm={8}>
              <div className="hero-h1">
                <h1>
                  Where Ambition <br />
                  Meets Achievement.
                </h1>
              </div>
            </Col>
            <Col sm={6}>
              <div className="hero-left">
                <p>
                  Charting Your Path to Mastery. Benefit from content shaped by
                  <br></br>seasoned tutors, offering expansive{" "}
                  <span>question banks</span>,<br></br>
                  precision-targeted subtopic exams,
                  <br></br>
                  <span>past papers</span> decoded with in-depth solutions and
                  clear,
                  <br></br>concise breakdowns of <span>key concepts</span>.
                  <br></br>
                  <br></br>Let your academic evolution begin here.
                </p>
              </div>

              <div className="hreo-button-bottom">
                <Link to="/All-Curriculum" className="hreo-button-see-all">
                  See All Curriculums
                </Link>
              </div>
            </Col>
          </Row>
          {/* Splatter Image */}
          <img
            src={BGRight1}
            alt="Splatter"
            className="splatter-image-right-1"
          />
          <img
            src={BGRight2}
            alt="Splatter"
            className="splatter-image-right-2"
          />
          <img
            src={BGMiddle}
            alt="Splatter"
            className="splatter-image-middle"
          />
          <img src={Wave} alt="Splatter" className="splatter-image-left" />
          {/* <img src={BGBottomRight} alt="Splatter" className="splatter-image-bottom-right" /> */}
        </Container>
      </section>
  
    </>
  );
};

export default Hero;
