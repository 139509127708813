import React, { useState, useEffect } from "react";
import Isotope from "isotope-layout";
import { NavLink, useHistory } from 'react-router-dom';
import "../key-concept/keyconcept.css";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import img_7 from "../img/for.png";
import img_1 from "../img/icon/Clock.png";
import img_2 from "../img/icon/Certificate_1.png";
import img_3 from "../img/icon/Matemathic.png";
import { useGetQuizunitefindQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png";
import { useSelector } from "react-redux";

const Unit = (props) => {
  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);

  const [iso, setIso] = useState(null);
  const Easy = "Easy";
  const Medium = "Medium";
  const Hard = "Hard";

  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);

  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const unitdata = useGetQuizunitefindQuery(props.match.params.slug);

  const purchasedCourses = useSelector((state) => state.user.purchasedCourses);

  if (unitdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (unitdata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (unitdata.isError) return <h1>An error occured {unitdata.error.error}</h1>;

  const isCourseAccessible = (courseId) => {
    // Find the matching record in purchasedCourses
    const course = purchasedCourses.find((c) => c.course_id === courseId);
    if (!course) return false; // not purchased at all
  
    // Check if it's still within the subscription end date
    const now = new Date().getTime() / 1000; // current time in Unix seconds
    return course.end_date >= now;
  };
  console.log("purchasedCourses:", purchasedCourses); 
  console.log("unitdata.data[0]:", unitdata.data[0]);
  console.log("neededCourseId:", unitdata.data[0]?.course_id);
  

  const neededCourseId = unitdata.data[0]?.apcourse_id;  // <-- use apcourse_id

  if (!isCourseAccessible(neededCourseId)) {
    history.push("/payment");
    return null; // stop rendering, so no further UI is shown
  }


  return (
    <section className="quiz-section">
      <div className="top-header-quiz">
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-30">
              <Col sm={12}>
                <div className="ap-heading">
                  <Row className="quiz_row_page">
                    <Col
                      md={{ span: 6, offset: 3 }}
                      className="quiz_top_header"
                    >
                      <div className="quiz_top_header_top pb-60">
                        <p>Let’s Practice</p>
                        <h2>{unitdata.data[0].couresname}</h2>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" />
                        <img src={qu_top_w} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="quiz_question_top">
                  <p>Topic:</p>
                  <p>{unitdata.data[0].name}</p>
                  {/* <p className="pl-30">
                    1.1 Introducing Calculus: Can Change Occur at an Instant?{' '}
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="unit-page ">
        <Container>
          <Row>
            <Col sm={12}>
              <img
                src={BGRight1}
                alt="Splatter"
                className="splatter-image-right-1"
              />
              <img
                src={BGRight2}
                alt="Splatter"
                className="splatter-image-right-2"
              />
              <img
                src={BGMiddle}
                alt="Splatter"
                className="splatter-image-middle"
              />
              <div className="quiz_main_content pt-30 pb-60">
                <div className="quiz_main_content_box">
                  <img src={img_1} /> No Time Limit
                </div>
                <div className="quiz_main_content_box">
                  <img src={img_2} /> Earn NP
                </div>
                <div className="quiz_main_content_box">
                  <img src={img_3} /> Challenge yourself... get all 10 correct.
                </div>
              </div>
              <div className="quiz_main_bottom_content">
                <p>
                  Note: **There are 10 questions in this Quiz, click{" "}
                  <span>start</span> button to start the Quiz.
                </p>
              </div>
              <div className="display-flex">
                <div className="quiz_bottom_button">
                  <Link
                    to={"/Quiz-Question/" + unitdata.data[0].slug}
                    className="quiz-box"
                  >
                    Start Quiz
                  </Link>
                </div>
                <div className="quiz_bottom_right">
                  <p>Save time with our</p>
                  <Button variant="popup-button-1" onClick={handleShow}>
                    Keyboard Shortcuts
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        className="Keyboard-Shortcuts"
        size="lg"
        centered
        show={showh}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pb-120">
          <h4 className="mb-30">Keyboard Shortcuts</h4>
          <div className="Keyboard-Shortcuts-box-content">
            <p>Submit Answer or Continue</p>
            <div className="Keyboard-Shortcuts-box-content-button">
              <p>ENTER</p>
            </div>
          </div>
          <div className="Keyboard-Shortcuts-box-content">
            <p>Select Multiple Choice Answer</p>
            <div className="Keyboard-Shortcuts-box-content-button">
              <p>a</p>
              <p>b</p>
              <p>c</p>
              <p>d</p>
            </div>
          </div>
          <div className="Keyboard-Shortcuts-box-content">
            <p>Reset Blanks Exercise </p>
            <div className="Keyboard-Shortcuts-box-content-button">
              <p>R</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </section>
  );
};

export default Unit;
