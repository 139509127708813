import { useEffect } from "react";

const MathJaxRenderer = ({ children }) => {
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise()
        .then(() => console.log("MathJax rendering complete"))
        .catch((err) => console.error("MathJax rendering failed", err));
    }
  }, [children]);

  return <div>{children}</div>;
};

export default MathJaxRenderer;
