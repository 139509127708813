// src/features/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  name: "",
  phone: "",
  country: "",
  school: "",
  plane_status: "",
  purchasedCourses: [], // Store full course details [{ course_id, plan_id, plan_name, end_date, invoice }]
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.email = action.payload.email || "";
      state.name = action.payload.name || "";
      state.phone = action.payload.phone || "";
      state.country = action.payload.country || "";
      state.school = action.payload.school || "";
      state.plane_status = action.payload.plane_status || "";
    },
    setUserCourses(state, action) {
      state.purchasedCourses = action.payload || []; // Full course info array
    },
    unsetUserInfo(state) {
      return initialState; // Reset state to initial values
    },
  },
});

export const { setUserInfo, unsetUserInfo, setUserCourses } = userSlice.actions;
export default userSlice.reducer;
