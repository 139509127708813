import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';
import Right_arror from '../img/icon/right-arror.png';
import Right_arror_w from '../img/icon/Expand_right_light_w.png';
import { useGetAlltopicQuery } from '../services/aplms';
import Loader from '../loader/Loader';

const KeyTopic = (props) => {
  const slug = props.propsdata;
  const topicdata = useGetAlltopicQuery(slug);

  if (topicdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  // Check if there is no topic data
  if (!topicdata.data || topicdata.data.length === 0)
    return (
      <div className="coming-soon">
        <h1>Coming Soon</h1>
      </div>
    );

  if (topicdata.isError)
    return <h1>An error occurred {topicdata.error.error}</h1>;

  return (
    <>
      <Row>
        {topicdata.data.map((topic) => (
          <Col sm={4} key={topic.id || topic.slug}>
            <NavLink to={'/Unit/' + slug} className="qu-box">
              <h4>{topic.name}</h4>
              <div className="right-arror-key">
                <img src={Right_arror} className="d" alt="arrow" />
                <img src={Right_arror_w} alt="arrow white" />
              </div>
            </NavLink>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default KeyTopic;
