import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./login.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "../layout/header/Header";
import Footer from "./Footer";
import logo_d from "../img/logo/black-logo.png";
import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";
import MailIcon from "../img/icon/MailIcon.png";
import { useResetPasswordMutation } from "../services/aplms";

const PasswordResetPage = () => {
  const { token } = useParams();
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const [changeUserPassword] = useResetPasswordMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update the form data state
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { password, confirm_password } = formData;

    // Validation: Ensure both fields are filled
    if (!password || !confirm_password) {
      setError({
        status: true,
        msg: "All fields are required.",
        type: "error",
      });
      return;
    }

    // Validation: Check for minimum length
    if (password.length < 8) {
      setError({
        status: true,
        msg: "Password must be at least 8 characters.",
        type: "error",
      });
      return;
    }

    // Validation: Check that passwords match
    if (password !== confirm_password) {
      setError({
        status: true,
        msg: "Passwords do not match.",
        type: "error",
      });
      return;
    }

    // Optional: Additional password strength validation can be added here
    // For example, enforcing uppercase, lowercase, and a digit:
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    // if (!passwordRegex.test(password)) {
    //   setError({
    //     status: true,
    //     msg: "Password must include uppercase, lowercase letters, and a number.",
    //     type: "error",
    //   });
    //   return;
    // }

    try {
      // Attempt to change the password using the provided token
      const res = await changeUserPassword({
        actualData: { password, confirm_password },
        token,
      });

      if (res?.data?.status === "success") {
        // Clear the form data and show a success message
        setFormData({ password: "", confirm_password: "" });
        setError({
          status: true,
          msg: "Password changed successfully!",
          type: "success",
        });
      } else {
        setError({
          status: true,
          msg: res?.data?.msg || "Something went wrong. Please try again.",
          type: "error",
        });
      }
    } catch (err) {
      console.error("Password reset error:", err);
      setError({
        status: true,
        msg: "Failed to reset password. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <section className="password-reset">
      <Header />
      <div style={{ height: "100px" }}></div>
      <img src={BGRight1} alt="Splatter" className="splatter-image-right-1-login" />
      <img src={BGRight2} alt="Splatter" className="splatter-image-right-2-login" />
      <img src={BGMiddle} alt="Splatter" className="splatter-image-middle-login" />
      <Container>
        <Row className="login-from">
          <Col sm={6} className="width-from">
            <div className="login-top-logo">
              <Link to="/">
                <img src={logo_d} className="lite" alt="logo" />
              </Link>
            </div>
          </Col>
          <Col sm={6}>
            <div>
              <h4>Password Reset</h4>

              {error.status && (
                <div className={`error-message show ${error.type === "success" ? "success" : "error"}`}>
                  {error.msg}
                </div>
              )}

              <p className="from-bottom-text">
                Enter your new password below.
              </p>

              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Form.Group className="mb-4 email-input-group">
                  <div className="input-icon-wrapper">
                    <img src={MailIcon} alt="Password Icon" className="input-icon" />
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="custom-input email-input"
                      placeholder="New Password"
                      required
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-4 email-input-group">
                  <div className="input-icon-wrapper">
                    <img src={MailIcon} alt="Confirm Password Icon" className="input-icon" />
                    <Form.Control
                      type="password"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                      className="custom-input email-input"
                      placeholder="Confirm Password"
                      required
                    />
                  </div>
                </Form.Group>

                <Button className="Signup-Button" type="submit">
                  Reset Password
                </Button>

                <p className="from-bottom-text">
                  By clicking on "Reset Password," you agree to our{" "}
                  <span>Terms of Service</span> and <span>Privacy Policy</span>.
                </p>

                <p className="forgot-password-text">
                  Changed your mind?{" "}
                  <Link to="/login" className="login-button-style">
                    Go back to Login
                  </Link>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
    </section>
  );
};

export default PasswordResetPage;
