import React from "react";
import "./courses.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink, useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import { useGetAllletestcoursesQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import Key from "../img/icon/card_key.png";
import Question from "../img/icon/card_quiz.png";
import Past from "../img/icon/card_book.png";
import Quiz from "../img/icon/card_q.png";
import Key_w from "../img/icon/card_key_w.png";
import Question_w from "../img/icon/card_quiz_w.png";
import Past_w from "../img/icon/card_book_w.png";
import Quiz_w from "../img/icon/card_q_w.png";

const Courses = () => {
  const history = useHistory();

  // Get purchased courses from Redux store
  const purchasedCourses = useSelector((state) => state.user.purchasedCourses);

  // Function to check if a course is purchased and active
  const isCourseAccessible = (courseId) => {
    const course = purchasedCourses.find((c) => c.course_id === courseId);

    if (!course) return false; // Not purchased

    const today = new Date().getTime() / 1000; // Convert current time to Unix timestamp
    return course.end_date >= today; // Returns true if plan is active
  };

  // Fetch All Latest Courses
  const {
    data: coursesData,
    isLoading,
    isError,
  } = useGetAllletestcoursesQuery();

  if (isLoading) return <Loader />;
  if (isError) return <Error />;

  // Handle navigation logic
  const handleNavigation = (e, courseId) => {
    if (!isCourseAccessible(courseId)) {
      e.preventDefault(); // Prevent navigation
      history.push("/payment"); // Redirect to Payment Page
    }
  };

  return (
    <>
      <section className="latest-courses pt-100 pb-100 ">
        <Container>
          <h4 className="latest-courses-title">Latest Courses</h4>
          <Row>
            {coursesData.map((course) => (
              <Col sm={4} key={course.id}>
                <div className="courses-box">
                  <div className="courses-box-top">
                    <div className="img_box_shadow">
                      <img src={course.favicon_icon} alt={course.name} />
                    </div>
                  </div>
                  <div className="courses-box-title">
                    <h4>{course.name}</h4>
                    <div className="courses-box-content">
                      {/* First Row */}
                      <div className="courses-box-row">
                        <div className="courses-box-content-button">
                          <NavLink
                            to={`/Key-Concept/${course.slug}`}
                            className="courses-button-style" // <-- no paywall logic here
                          >
                            <img src={Key} className="d" alt="Key Concepts" />
                            <img src={Key_w} alt="Key Concepts" /> Key Concepts
                          </NavLink>
                        </div>
                        <div className="courses-box-content-button">
                          <NavLink
                            to={`/Question-Bank/${course.slug}`}
                            className={`courses-button-style ${
                              isCourseAccessible(course.id)
                                ? ""
                                : "disabled-link"
                            }`}
                            onClick={(e) => handleNavigation(e, course.id)}
                          >
                            <img
                              src={Question}
                              className="d"
                              alt="Question Bank"
                            />
                            <img src={Question_w} alt="Question Bank" />{" "}
                            Question Bank
                          </NavLink>
                        </div>
                      </div>
                      {/* Second Row */}
                      <div className="courses-box-row">
                        <div className="courses-box-content-button">
                          <NavLink
                            to={`/Past-Paper-Unit/${course.slug}`}
                            className="courses-button-style"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href =
                                "https://slyacademy.com/past-papers/";
                            }}
                          >
                            <img src={Past} className="d" alt="Past Papers" />
                            <img src={Past_w} alt="Past Papers" /> Past Papers
                          </NavLink>
                        </div>
                        <div className="courses-box-content-button">
                          <NavLink
                            to={`/Quiz/${course.slug}`}
                            className={`courses-button-style ${
                              isCourseAccessible(course.id)
                                ? ""
                                : "disabled-link"
                            }`}
                            onClick={(e) => handleNavigation(e, course.id)}
                          >
                            <img src={Quiz} className="d" alt="Quizzes" />
                            <img src={Quiz_w} alt="Quizzes" /> Quizzes
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

    </>
  );
};

export default Courses;
