import React from "react";
import "./speaks.css";

const Speaks = () => {
  return (
    <div className="speaks-wrapper">
      <h2 className="title">Join Us Now, From <span className="highlight">$39.00</span>.</h2>
      <div className="speaks-content">
        <p className="description">
          Transform your education experience with the Num8ers platform. Whether you're a teacher or a student, our tools are designed to enhance learning and make progress measurable. Join us today with pricing starting at just <span className="highlight">$39.00</span> per month and unlock your full potential.
          Improve learning outcomes, streamline educational processes, and create a brighter future. Take the step to better education today!
        </p>
        <a href="/Payment" className="cta-button">
          Get Started
        </a>
      </div>
    </div>
  );
};

export default Speaks;
